import { CategorySideBar, PostMainContent } from "../../Sections"
import "./SinglePost.css"

const SinglePost = () => {
    return (
        <div className="container-fluid about-us blog">
            <div className="row m-0 p-0">
                <CategorySideBar from="singlePost" />
                <PostMainContent />
            </div>
        </div>
    )
}

export default SinglePost