import "./OurStory.css"

const OurStory = () => {
    return (
        <div className="container-fluid our-story ">
            <h3>Boushahri Group Medical was established</h3>
            <h2>as an independent legal entity in 2009</h2>
            <p>Boushahri Group Medical was established as an independent legal entity in 2009. The company has spread its roots into all
                sectors of the medical industry with a signifcant presence in the Pharmaceuticals, Dermatology & Aesthetics sectors.
            </p>
            <p>Boushahri Group Medical is today one of the top 10 leading providers of healthcare solutions and a trusted partner to public
                and private healthcare institutions in Kuwait.
            </p>
            <p>Boushahri Group Medical Co is established in Bahrain at the beginning of 2022 furthering Mr. Mohammed Boushahri’s
                ambition of being a healthcare leader not only in Kuwait but in the region of GCC.In the pipeline for the coming year are
                expansion plans in the Sultanate of Oman.
            </p>
        </div>
    )
}

export default OurStory