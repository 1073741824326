import { FC } from "react"
import "./SideBar.css"
import { NavLink } from "react-router-dom"
import { SideBarProps } from "../../Models/AboutUS/AboutUsTypes"

const SideBar: FC<SideBarProps> = ({ linksArray }) => {
    const links = linksArray.map(link => (
        <li key={link.id}>
            <NavLink to={link.to} end={true}>
                {link.title}
            </NavLink>
        </li>
    ))
    return (
        <ul className="m-0 sidebar">
            {links}
        </ul>
    )
}

export default SideBar