import { ListOfGoalsData } from "../../../Assets/Data"
import "./ListOfGoals.css"

const ListOfGoals = () => {
    const listOfGoals = ListOfGoalsData.map(listOfGoals => (
        <div className="col" key={listOfGoals.id}>
            <h2>{listOfGoals.title}</h2>
            <p>{listOfGoals.description}
            </p>
        </div>

    ))
    return (
        <div className="list-of-goals container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfGoals}
            </div>
        </div>
    )
}

export default ListOfGoals