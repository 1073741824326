import { ListOfServicesData } from "../../../Assets/Data"
import "./ListOfServices.css"

const ListOfServices = () => {
    const listOfServices = ListOfServicesData.map(item => (
        <div className="col" key={item.id}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
        </div>
    ))
    return (
        <div className="list-of-services container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfServices}
            </div>
        </div>
    )
}

export default ListOfServices