import { ListOfWarehouses } from "../../../../Assets/Data"
import { FullImgHero } from "../../../../Components"
import "./OurWarehouse.css"

const OurWarehouse = () => {
    const listOfWarehouses = ListOfWarehouses.map(item => (
        <div className="col" key={item.id}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
        </div>
    ))
    return (
        <>
            <FullImgHero additionalClass="our-warehouse-hero" />
            <div className="list-of-warehouses container-fluid">
                <div className="row row-cols-2 row-cols-md-3">
                    {listOfWarehouses}
                </div>
            </div>
        </>
    )
}

export default OurWarehouse