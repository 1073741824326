import { LinksProps } from "../../../Models/Header/HeaderTypes"


const Links: LinksProps[] = [
    {
        id: 1,
        to: "/",
        title: "Home"
    },
    {
        id: 2,
        to: "/about-us",
        title: "ABOUT US"
    },
    {
        id: 3,
        to: "/services",
        title: "SERVICES"
    },
    {
        id: 4,
        to: "/partners",
        title: "PARTNERS"
    },
    {
        id: 5,
        to: "/blogs",
        title: "BLOGS"
    },
    {
        id: 6,
        to: "/contact-us",
        title: "CONTACT"
    }
]
export default Links