import "./Partners.css"
import { CategorySideBar, MainContent } from "../../Sections"
import useGet from "../../Custom Hooks/useGet"
import PartnersService from "../../Services/PartnersService"
const Partners = () => {
    const [data, loading] = useGet(PartnersService.getCategory())
    return (
        <div className="container-fluid about-us">
            <div className="row m-0 p-0">
                <CategorySideBar data={data} />
                <MainContent />
            </div>
        </div>
    )
}

export default Partners