import "./Home.css"

const Home = () => {
    return (
        <>
            <div className="container-fluid home bg-full">
                <p>Products that matter, <br />
                    <span>transforming lives</span>
                </p>
            </div>
        </>
    )
}

export default Home