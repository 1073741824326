import { FullImgHero } from "../../../../Components"
import { ListOfGoals } from "../../../../Sections"
import "./OurGoals.css"

const OurGoals = () => {

    return (
        <>
            <FullImgHero additionalClass="our-goals-hero">
                <div className="text">
                    <h2>Products that matter,<br />
                        <span>transforming lives</span>
                    </h2>
                </div>
            </FullImgHero>
            <ListOfGoals />

        </>
    )
}

export default OurGoals