import "./OurNetworkDescription.css"

const OurNetworkDescription = () => {
    return (
        <div className="our-network-description container-fluid">
            <p>We pride ourselves with a team of professionals and specialists that have forged strong relations with leading medical and
                pharmaceutical manufacturers in the world.
            </p>
            <p>Our strength lies as well in our ability to maintain our
                performance and partnerships on the long-term since our beginnings in the eighties.
            </p>
            <p>In addition to our performance, Boushahri Group Medical continues to expand its network of clients which comprises public
                health institutions and private sector clinics, hospitals and pharmacies.
            </p>
        </div>
    )
}

export default OurNetworkDescription