import { menuMotionProps, smallMenuMotionProps } from "../../../Models/Header/HeaderTypes";



export const menuMotion: menuMotionProps = {
    initial: {
        width: "0%",
        padding: "0px",
        paddingRight: "0",
        transition: { duration: 0.5 }
    },
    animate: {
        width: "20%",
        padding: "65px 60px 0px 0px",
        paddingRight: "60px",
        transition: { duration: 0.5 }
    },
    exit: {
        width: "0%",
        opacity: 0,
        paddingRight: "0",
        transition: { duration: 0.5 },
    },
}
export const smallMenuMotion: smallMenuMotionProps = {
    initial: {
        paddingTop: "0px",
        paddingBottom: "0px",
        transition: { duration: 0.5 }
    },
    animate: {
        paddingTop: "40px",
        paddingBottom: "35px",
        transition: { duration: 0.5 }
    },
    exit: {
        paddingTop: "0px",
        paddingBottom: "0px",
        opacity: 0,
        transition: { duration: 0.5 },
    },
}