
import { FullImgHero } from "../../Components"
import { MisionVision, OurStory } from "../../Sections"
import "./style.css"
const index = () => {

    return (
        <>
            <FullImgHero additionalClass="about-us-hero" />
            <OurStory />
            <MisionVision />
        </>
    )
}

export default index