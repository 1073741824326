import "./MainContent.css"
import RightArrow from "../../../Assets/Images/Partners/rightArrow.svg"
import { SocialMedia } from "../../../Assets/Data"
import { SocialIcon } from "../../../Components"
import { useContext, useEffect } from "react"
import { CategoryID } from "../../../Context/CategoryID"
import usePost from "../../../Custom Hooks/usePost"
import BlogService from "../../../Services/BlogService"
import { Link } from "react-router-dom"
const MainContent = () => {
    const { categoryId } = useContext(CategoryID)
    const [handleGet, , data] = usePost(BlogService.getBlogs)
    useEffect(() => {
        handleGet({ type_blog_id: categoryId })
    }, [categoryId])
    const socials = SocialMedia.map(social => (
        <SocialIcon social={social} key={social.id} />
    ))

    const blogs = data?.data?.data?.blogs?.map((blog: any) => (
        <div className="blog-main-content">
            <div className="blog-img bg-full" style={
                {
                    backgroundImage: blog.image != null ? `url(${blog.image})` : ""
                }
            }></div>
            <div className="justi-between-row-align-start text-read-more">
                <div className="text">
                    <h2>{blog.title}</h2>
                    <p>
                        {blog.description}
                    </p>
                </div>
                <Link to={`/blog/post/${blog.id}`}>Read More
                    <span>
                        <img src={RightArrow} alt="right-arrow" />
                    </span>
                </Link>
            </div>
            <div className="flex-start-center ">
                <p>Share</p>
                {socials}
            </div>
        </div>
    ))
    return (
        <div className="col-12 col-md-10 p-0 ">
            {blogs}
        </div>
    )
}

export default MainContent