import { PublicAxios } from "./AxiosHandler"

class PartnersService {
    static endPoint = "partner"
    static getAll(body: { category_id: number }) {
        return PublicAxios.post(`${PartnersService.endPoint}/all`, body)
    }
    static getCategory() {
        return PublicAxios.get(`${PartnersService.endPoint}/category`)
    }
}
export default PartnersService