import { LinksProps } from "../../../../Models/Header/HeaderTypes"

const OurServicesLinks: LinksProps[] = [
    {
        id: 1,
        to: "/services",
        title: "Our Services"
    },
    {
        id: 2,
        to: "our-network",
        title: "Our Network"
    },
    {
        id: 3,
        to: "our-warehouse",
        title: "Our Warehouse"
    },
    {
        id: 4,
        to: "our-systems",
        title: "Our Systems"
    }
]
export default OurServicesLinks