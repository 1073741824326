import React, { FC, Fragment, useEffect, useState } from "react"
import "./Menu.css"
import { AnimatePresence, motion } from "framer-motion"
import Close from "../../../Assets/Images/Header/close.svg"
import { menuMotion } from "../../../Utils"
import { MenuProps } from "../../../Models/Header/HeaderTypes"
import { Links } from "../../../Assets/Data"
import { Link } from "react-router-dom"

const Menu: FC<MenuProps> = ({ setIsVisible, isVisible }) => {
    const links = Links.map(link => (
        <li key={link.id}>
            <Link to={link.to}>
                {link.title}
            </Link>
        </li>
    ))
    return (
        <>
            <AnimatePresence mode="wait">
                {isVisible == true ?
                    <motion.div
                        variants={menuMotion}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key="1"
                        className="menu-header flex-col-align-end">
                        <motion.img
                            exit="exit"
                            src={Close} alt="close-icon" onClick={() => setIsVisible(!isVisible)} />

                        <ul>
                            {links}
                        </ul>
                    </motion.div>
                    : <Fragment key="2"></Fragment>}
            </AnimatePresence>
        </>
    )
}

export default Menu