import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes"



const ListOfGoalsData: ListOfDataProps[] = [
    {
        id: 1,
        title: "Our Ultimate Goals",
        description: "Provide our community with best products and services."
    },
    {
        id: 2,
        title: "Short Term Goals",
        description: "Establishing BGMC in GCC as frst diferentiated medical company."
    },
    {
        id: 3,
        title: "Long Term Goals",
        description: "Expansion to Middle East countries."
    },
    {
        id: 4,
        title: "Where We Stand Today",
        description: "Top 10 leaders in health care sector in Kuwait market."
    }
]
export default ListOfGoalsData