import { FC } from "react"
import "./SocialIcon.css"
import { SocialTypes } from "../../Models/SocialTypes/SocialTypes"

type SocialIcon = {
    social: SocialTypes
}
const SocialIcon: FC<SocialIcon> = ({ social }) => {
    return (
        <a className="social flex-center" target="_blank" href={social.to} key={social.id}>
            {social.icon}
        </a>
    )
}

export default SocialIcon