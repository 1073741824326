import { MisionVisionProps } from "../../../../Models/AboutUS/AboutUsTypes"

const MisionVisionData: MisionVisionProps[] = [
    {
        id: 1,
        title: "Vision",
        description: " To become a trusted contributor to the overall welfare and prosperity of the communities we serve."
    },
    {
        id: 2,
        title: "Mission",
        description: "We will strive to achieve our vision by fostering productive relationships, providing high-quality products, and continually enhancing everything we do."
    }
]
export default MisionVisionData