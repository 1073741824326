import { FullImgHero } from "../../../../Components"
import { TimeLine } from "../../../../Sections"
import "./OurHistory.css"
const OurHistory = () => {
    return (
        <>
            <FullImgHero additionalClass="our-history-hero" />
            <TimeLine />

        </>
    )
}

export default OurHistory