import { Outlet, useLocation } from "react-router-dom"
import { Footer, Header } from "./Sections"
import { useEffect, useState } from "react"
import CategoryIDPropvider from "./Context/CategoryID"

const Root = () => {
    const [isHome, setIsHome] = useState<boolean>(false)
    const location = useLocation()
    useEffect(() => {
        if (location.pathname == "/") {
            setIsHome(true)
        }
        else {
            setIsHome(false)
        }
    }, [location.pathname])
    return (
        <div className="App">
            <CategoryIDPropvider>
                <Header />
                <Outlet />
                {isHome == true ? <></> : <Footer />}
            </CategoryIDPropvider>
        </div>
    )
}

export default Root