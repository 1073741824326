import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes"

const ListOfServicesData: ListOfDataProps[] = [
    {
        id: 1,
        title: "Provide Healthcare Solutions",
        description: "We cover both private and public healthcare sectors in Kuwait market."
    },
    {
        id: 2,
        title: "Our Divisions",
        description: "We serve pharmaceutical, medical, cosmetic, and aesthetic products. As well as photography and digital services."
    }
]
export default ListOfServicesData