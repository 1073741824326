import "./Footer.css"
import logo1 from "../../Assets/Images/Footer/Vector (2).png";
import logo2 from "../../Assets/Images/Footer/Vector (3).png";
import logo3 from "../../Assets/Images/Footer/Vector (4).png";
import usePost from "../../Custom Hooks/usePost";
import SubscribeService from "../../Services/SubscribeService";
import { useState } from "react";

const Footer = () => {
    const [subscribe, loading] = usePost(SubscribeService.post)
    const [email, setEmail] = useState<string>("")
    const Subscribe = () => {
        subscribe({
            email: email
        })
    }
    return (
        <div className="container-fluid footer flex-center">
            <div className="container">
                <div className="row row-cols-md-5 row-cols-2">
                    <div className="col">
                        <div className="content-footer">
                            <h4>ABOUT</h4>
                            <p>Overview</p>
                            <p>Our Goals</p>
                            <p>Our Certifcation</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>SERVICES</h4>
                            <p>Overview</p>
                            <p>Medical Warehouse</p>
                            <p>Our Systems</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>PARTNERS</h4>
                            <p>Aesthetic</p>
                            <p>Pharmaceuticals</p>
                            <p>Medical Equipment</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>CONTACTS</h4>
                            <p>info@boushahrin.com</p>
                            <p>+965 2220000</p>
                            <div className="icons-footer flex">
                                <div className="icons-vector-footer flex-center bg-full">
                                    <img src={logo1} />
                                </div>
                                <div className="icons-vector-footer flex-center bg-full">
                                    <img src={logo2} />
                                </div>
                                <div className="icons-vector-footer flex-center bg-full">
                                    <img src={logo3} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>SUBSCRIBE</h4>
                            <p>to our newsletter</p>
                            <div className="input-footer">
                                <input type="text" onChange={(e) => setEmail(e.target.value)} />

                            </div>
                            <div className="button-footer flex-center">
                                <button onClick={Subscribe}>
                                    {loading == null ? "Subscribe" :
                                        loading == "loading" ? "loading..." :
                                            loading == true ? "Email Has Been send Successfully" :
                                                "Something went Wrong"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <p>© All rights reserved 2024, Boushahri Group Medical</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;