import { SystemsData } from "../../../../Assets/Data"
import { FullImgHero, System } from "../../../../Components"
import "./OurSystems.css"

const OurSystems = () => {
    const systems = SystemsData.map(system => (
        <System system={system} />
    ))
    return (
        <>
            {systems}
        </>
    )
}

export default OurSystems