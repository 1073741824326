import { AxiosResponse } from 'axios';
import { useState } from 'react'
type OnSuccessCallback = (res?: AxiosResponse) => void;
type EndpointFunction = (data: any) => Promise<any>;
const usePost = (endPoint: EndpointFunction, onSuccess?: OnSuccessCallback): [(data: any, onSubmitProps?: any) => void, boolean | null | string, any] => {
    const [loading, setLoading] = useState<boolean | null | string>(null)
    const [data, setData] = useState<null | AxiosResponse>(null)
    const handleRequest = (data: any) => {
        setLoading("loading")
        endPoint(data)
            .then(res => {
                setLoading(true)
                console.log(res)
                setData(res)
                setTimeout(() => {
                    setLoading(null)
                }, 2000)
                if (onSuccess) {
                    onSuccess(res)
                }

            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                setTimeout(() => {
                    setLoading(null)
                }, 2000)
            })
    }

    return [handleRequest, loading, data]
}

export default usePost