import { FC } from "react"
import { FullImgHero } from "../"
import { SystemsDataProps } from "../../Assets/Data/OurSystems/SystemsData"
import "./System.css"
type SystemProps = {
    system: SystemsDataProps
}
const System: FC<SystemProps> = ({ system }) => {
    return (
        <div className="systems container-fluid p-0">
            <FullImgHero additionalClass="ma3jon p-0">
                <div className={`${system.imgClassName} bg-full`}></div>
                <div className={`${system.logoClassName} bg-full`}></div>
            </FullImgHero>
            <div className="systems-text">
                <p>
                    {system.descriprion}
                </p>

            </div>
        </div>
    )
}

export default System