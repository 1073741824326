import "./Gallery.css"
import Cirtificate from "../../../Assets/Images/AboutUs/OurCertification/cirtificate.png"
const Gallery = () => {
    return (
        <div className="gallery row row-cols-1 row-cols-md-3">
            <div className="col">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
            <div className="col">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
            <div className="col">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
        </div>
    )
}

export default Gallery