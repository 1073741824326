import { FC, useContext } from "react"
import "./CategorySideBar.css"
import { Link } from "react-router-dom"
import RightArrow from "../../Assets/Images/Partners/rightArrow.svg"
import { CategoryID } from "../../Context/CategoryID"
import clsx from "clsx"
type CategorySideBarProps = {
    from?: string,
    data?: any
}
const CategorySideBar: FC<CategorySideBarProps> = ({ from, data }) => {
    const { setCategoryId, categoryId } = useContext(CategoryID)


    const GetCategories = () => {
        if (from == "blog") {
            return data?.data?.data?.typeBlogs?.map((category: any) => (
                <li key={category.id} onClick={() => setCategoryId(category.id)} className={clsx(
                    'category',
                    { 'active': categoryId == category.id }
                )}>
                    {category.name}
                </li>
            ))
        }
        else {
            return data?.data?.data?.categoryPartners?.map((category: any) => (
                <li key={category.id} onClick={() => setCategoryId(category.id)} className={clsx(
                    'category',
                    { 'active': categoryId == category.id }
                )}>
                    {category.name}
                </li>
            ))
        }
    }
    return (
        <div className="col-12 col-md-2 p-0">

            <ul className="m-0 sidebar">
                {from == "blog" ? <p className="Categories">Categories</p>
                    : from == "singlePost" ?
                        <Link to="/blogs" className="Blog">
                            <span><img src={RightArrow} alt="" />
                            </span>
                            Blog
                        </Link> : <></>}
                {from == "singlePost" ? <></> :
                    from == "blog" ? <>
                        {GetCategories()}</> :
                        <>
                            {GetCategories()}
                        </>}

            </ul>
        </div>
    )
}

export default CategorySideBar