import { TimeLineData } from "../../../Assets/Data"
import "./TimeLine.css"

const TimeLine = () => {
    const timeLine = TimeLineData.map(item => (
        <div className="date-text-con" key={item.id}>
            <p className="m-0">
                <span>{item.date}</span>
                {item.title}
            </p>
        </div>
    ))
    return (
        <div className="small-time-line">
            <div className="container-fluid time-line-con">
                {timeLine}
            </div >
        </div>
    )
}

export default TimeLine