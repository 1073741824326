import { createContext, Dispatch, FC, SetStateAction, useState } from "react"

export const CategoryID = createContext<{
    setCategoryId: Dispatch<SetStateAction<number | null>>;
    categoryId: number | null

}>({
    setCategoryId: () => { },
    categoryId: 1
})


type CategoryProviderProps = {
    children: React.ReactNode
}

const CategoryIDPropvider: FC<CategoryProviderProps> = ({ children }) => {
    const [categoryId, setCategoryId] = useState<number | null>(null)
    return (
        <CategoryID.Provider value={{
            categoryId,
            setCategoryId
        }}>
            {children}
        </CategoryID.Provider>
    )
}

export default CategoryIDPropvider