import { FullImgHero } from "../../../../Components"
import { OurNetworkDescription } from "../../../../Sections"
import "./OurNetwork.css"

const OurNetwork = () => {
    return (
        <>
            <FullImgHero additionalClass="our-network-hero">
                <div className="text">
                    <h2>Leading International Brands<br />
                        <span>Local Presence</span>
                    </h2>
                </div>
            </FullImgHero>
            <OurNetworkDescription />
        </>
    )
}

export default OurNetwork