import { AnimatePresence, motion } from "framer-motion"
import "./SmallScreenMenu.css"
import { smallMenuMotion } from "../../../Utils/Motions/Header/HeaderMotions"
import Close from "../../../Assets/Images/Header/close.svg"
import { FC, Fragment } from "react"
import { MenuProps } from "../../../Models/Header/HeaderTypes"
import { Links } from "../../../Assets/Data"
import { Link } from "react-router-dom"
const SmallScreenMenu: FC<MenuProps> = ({ isVisible, setIsVisible }) => {
    const links = Links.map(link => (
        <li key={link.id}>
            <Link to={link.to}>
                {link.title}
            </Link>
        </li>
    ))
    return (
        <AnimatePresence>
            {isVisible ?
                <motion.div
                    key="1"
                    variants={smallMenuMotion}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="small-screen-menu justi-between-row-align-start">
                    <motion.ul exit="exit" className="m-0 p-0">
                        {links}
                    </motion.ul>
                    <motion.img src={Close} alt="close-icon" onClick={() => setIsVisible(!isVisible)} />
                </motion.div>
                : <Fragment key="2"></Fragment>}
        </AnimatePresence>
    )
}

export default SmallScreenMenu