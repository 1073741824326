import React, { FC } from "react"
import "./BurgerIcon.css"
import { BurgerIconProps } from "../../../Models/Header/HeaderTypes"

const BurgerIcon: FC<BurgerIconProps> = ({ setIsVisible, isVisible }) => {
    return (
        <div className="burger-con" onClick={() => setIsVisible(!isVisible)}>
            <a className="qodef-opener-icon qodef-m qodef-source--predefined qodef-side-area-mobile-header-opener">
                <span className="qodef-m-icon qodef--open">
                    <svg className="qodef-svg--menu" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 45 15">
                        <line x1="0" y1="-3" x2="45" y2="-3" strokeWidth="2px"></line>
                        <line x1="0" y1="7.5" x2="45" y2="7.5" strokeWidth="2px"></line>
                        <line x1="0" y1="18.5" x2="45" y2="18.5" strokeWidth="2px"></line>
                    </svg>
                </span>
            </a>
        </div>
    )
}

export default BurgerIcon