import { RouteObject } from "react-router-dom";
import { index, OurCertification, OurGoals, OurHistory } from "../../Pages";
import { MainLayout, WithLinksLayout } from "../../Components";
import { AboutLinks } from "../../Assets/Data";


const AboutRoutes: RouteObject[] = [
    {
        path: "/about-us",
        element: WithLinksLayout({
            Component: MainLayout,
            linksArray: AboutLinks, // Use your specific links array here
        }),
        children: [
            {
                index: true,
                Component: index,
            },
            {
                path: "our-history",
                Component: OurHistory
            },
            {
                path: "our-certification",
                Component: OurCertification
            }
            ,
            {
                path: "our-goals",
                Component: OurGoals
            }
        ]
    }
]
export default AboutRoutes