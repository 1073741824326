import useGet from "../../Custom Hooks/useGet"
import { BlogMainContent, CategorySideBar } from "../../Sections"
import BlogService from "../../Services/BlogService"
import "./Blog.css"

const Blog = () => {
    const [data, loading] = useGet(BlogService.getBlogCategories())
    return (
        <>
            <div className="container-fluid about-us blog">
                <div className="row m-0 p-0">
                    <CategorySideBar from="blog" data={data} />
                    <BlogMainContent />
                </div>
            </div>
        </>
    )
}

export default Blog