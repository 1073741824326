import { RouteObject } from "react-router-dom";
import { MainLayout, WithLinksLayout } from "../../Components";
import { ContactData } from "../../Assets/Data";
import { Contact } from "../../Pages";

const ContactRoutes: RouteObject[] = [
    {
        path: "contact-us",
        element: WithLinksLayout({
            Component: MainLayout,
            linksArray: ContactData
        }),
        children: [
            {
                index: true,
                Component: Contact
            }
        ]
    }
]
export default ContactRoutes