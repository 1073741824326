import { LinksProps } from "../../../../Models/Header/HeaderTypes"

const AboutLinks: LinksProps[] = [
    {
        id: 1,
        to: "/about-us",
        title: "About Us"
    },
    {
        id: 2,
        to: "our-history",
        title: "Our History"
    },
    {
        id: 3,
        to: "our-certification",
        title: "Our Certification"
    },
    {
        id: 4,
        to: "our-goals",
        title: "Our Goals"
    }
]
export default AboutLinks