import { SocialTypes } from "../../../Models/SocialTypes/SocialTypes"
import Facebook from "../../Images/Contact/facebook.svg"
import LinkedIn from "../../Images/Contact/linkedin.svg"
const SocialMedia: SocialTypes[] = [
    {
        id: 1,
        icon: <img src={Facebook} alt="facebook-icon" />,
        to: "http://www.facebook.com/sharer.php?u=https://al-jabriya.com/blogs"
    },
    {
        id: 2,
        icon: <img src={LinkedIn} alt="linkedin-icon" />,
        to: "http://www.linkedin.com/shareArticle?mini=true&amp;url=https://al-jabriya.com/blogs"
    }
]
export default SocialMedia