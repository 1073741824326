import { AxiosResponse } from "axios";
import { useEffect, useState } from "react"
type OnSuccessCallback = (res?: AxiosResponse) => void;

const useGet = (endPoint: Promise<any>, onSuccess?: OnSuccessCallback, dependancy?: boolean): [any, boolean] => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>()
    useEffect(() => {
        setLoading(true)
        endPoint
            .then(res => {
                console.log(res)
                setLoading(false)
                setData(res)
                if (onSuccess) {
                    onSuccess(res)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }, [dependancy])
    return [data, loading]
}

export default useGet