import React from 'react';
import { FC } from 'react';
import { LinksProps } from '../../Models/Header/HeaderTypes';

interface WithLinksProps {
    Component: React.ComponentType<any>;
    linksArray: LinksProps[];
}

const WithLinksLayout: FC<WithLinksProps> = ({ Component, linksArray }) => {
    return (
        <Component linksArray={linksArray}>
            {/* Render children here if needed */}
        </Component>
    );
};

export default WithLinksLayout;