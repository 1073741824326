import "./LogoCon.css"
import logo from "../../../Assets/Images/Header/logo.svg"
import { FC } from "react"
import { LogoConProps } from "../../../Models/Header/HeaderTypes"


const LogoCon: FC<LogoConProps> = ({ isHome }) => {
    if (isHome === true) {
        return <div className="logo flex-row-align-end">
            <img src={logo} alt="logo" />
        </div>
    }
    else {
        return <div className="logo flex-row-align-end">
            <img src={logo} alt="logo" />
            <h4>Boushahri Group Medical</h4>
        </div>
    }
}

export default LogoCon