import { RouteObject } from "react-router-dom";
import { OurNetwork, OurServicesIndex, OurSystems, OurWarehouse } from "../../Pages";
import { MainLayout, WithLinksLayout } from "../../Components";
import { OurServicesLinks } from "../../Assets/Data";

const OurServicesRoutes: RouteObject[] = [
    {
        element: WithLinksLayout({
            Component: MainLayout,
            linksArray: OurServicesLinks
        }),
        path: "/services",
        children: [
            {
                index: true,
                Component: OurServicesIndex
            },
            {
                path: "our-network",
                Component: OurNetwork
            },
            {
                path: "our-warehouse",
                Component: OurWarehouse
            },
            {
                path: "our-systems",
                Component: OurSystems
            }
        ]
    },


]
export default OurServicesRoutes