import { PublicAxios } from "./AxiosHandler"

class BlogService {
    static endPoint = "blog"
    static getBlogCategories() {
        return PublicAxios.get(`${BlogService.endPoint}/type`)
    }
    static getBlogs(body: { type_blog_id: number }) {
        return PublicAxios.post(`${BlogService.endPoint}/all`, body)
    }
    static getSinglePost(body: { blog_id: number }) {
        return PublicAxios.post(`${BlogService.endPoint}/show`, body)
    }
}
export default BlogService