import { SocialMedia } from "../../../Assets/Data"
import { SocialIcon } from "../../../Components"
import RightArrow from "../../../Assets/Images/Partners/rightArrow.svg"
import "./MainContent.css"
import usePost from "../../../Custom Hooks/usePost"
import BlogService from "../../../Services/BlogService"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

const MainContent = () => {
    const params = useParams()
    const [getSingleBlog, , data] = usePost(BlogService.getSinglePost)
    const socials = SocialMedia.map(social => (
        <SocialIcon social={social} />
    ))
    useEffect(() => {
        getSingleBlog({
            blog_id: params.id
        })
    }, [])
    return (
        <div className="col-12 col-md-10 p-0">
            <div className="blog-main-content single-post-con">
                <div className="blog-img bg-full"></div>
                <div className="justi-between-row-align-start text-read-more">
                    <div className="text">
                        <h2>Title</h2>
                        <p>Create the products and technologies that drive the Create the products and technologies that drive the Create the products and technologies that drive the Create the products and technologies that drive the

                        </p>
                    </div>
                </div>
                <div className="flex-start-center ">
                    <p>Share</p>
                    {socials}
                </div>
            </div>
        </div>
    )
}

export default MainContent