import { TimeLineDataProps } from "../../../../Models/AboutUS/AboutUsTypes"


const TimeLineData: TimeLineDataProps[] = [
    {
        id: 1,
        date: "1968",
        title: "Boushahri Group Trading Company"
    },
    {
        id: 2,
        date: "1975",
        title: "Boushahri expanded into the healthcare industry"
    },
    {
        id: 3,
        date: "1980",
        title: "Strong partnerships with major international brands like Allergan"
    },
    {
        id: 4,
        date: "2009",
        title: "Boushahri Group Medical was established as an independent legal entity"
    },
    {
        id: 5,
        date: "2022",
        title: "Boushahri Group Medical Co is established in Bahrain"
    }
]
export default TimeLineData
