import { Outlet } from "react-router-dom"
import "./MainLayout.css"
import { FC } from "react"
import { SideBar } from "../../Sections"
import { MainLayoutProps } from "../../Models/MainLayout/MainLayoutTypes"
const MainLayout: FC<MainLayoutProps> = ({ linksArray }) => {

    return (
        <div className="container-fluid about-us">
            <div className="row m-0 p-0">
                <div className="col-12 col-md-2 p-0">
                    <SideBar linksArray={linksArray} />
                </div>
                <div className="col-12 col-md-10 p-0 flex-grow-1">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout