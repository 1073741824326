import { useState } from "react"
import { SocialData } from "../../Assets/Data"
import { SocialIcon } from "../../Components"
import usePost from "../../Custom Hooks/usePost"
import ContactService from "../../Services/ContactService"
import "./Contact.css"

const Contact = () => {
    const [handleSubmit, loading] = usePost(ContactService.post)
    const [name, setName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [message, setMessage] = useState<string>()
    const socials = SocialData.map(social => (
        <SocialIcon social={social} />
    ))
    const handleClick = () => {
        handleSubmit({
            name,
            email,
            phone,
            message
        })
    }
    return (
        <>
            <div className="bg-full map-con">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1739.1113345184845!2d48.05912272660973!3d29.33446940000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf9d9b4226b61d%3A0x85efd7380edece00!2z2YXYrNmF2YjYudipINio2YjYtNmH2LHZiiDZhdmK2K_ZitmD2KfZhA!5e0!3m2!1sen!2skw!4v1724325166600!5m2!1sen!2skw" loading="lazy"></iframe>
            </div>
            <div className="contact-details container-fluid ">
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col">
                        <div className="row row-cols-2">
                            <div className="col">
                                <input type="text" placeholder="Name *" name="name" onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col">
                                <input type="text" placeholder="Phone number" name="phone" onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="row row-cols-1">
                            <div className="col">
                                <input type="text" placeholder="Email*" name="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="row row-cols-1">
                            <div className="col">
                                <textarea placeholder="Message*" name="message" onChange={(e) => setMessage(e.target.value)} ></textarea>
                            </div>
                        </div>
                        <div className="send-con flex-end-center ">
                            <a className="send" onClick={handleClick}>Send</a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="contact-info">
                            <p><span>Email</span>  info@boushahrigm.com</p>
                            <p><span>Tel</span>  +965 2220000</p>
                            <div className="social-icon flex">
                                {socials}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact