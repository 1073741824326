import { SocialTypes } from "../../../Models/SocialTypes/SocialTypes"
import Facebook from "../../Images/Contact/facebook.svg"
import Insta from "../../Images/Contact/insta.svg"
import LinkedIn from "../../Images/Contact/linkedin.svg"
const SocialData: SocialTypes[] = [
    {
        id: 1,
        icon: <img src={Facebook} alt="facebook-icon" />,
        to: "https://www.facebook.com/boushahrigroupmedical/"
    },
    {
        id: 2,
        icon: <img src={Insta} alt="insta-icon" />,
        to: "https://www.instagram.com/boushahrigm/?hl=en"
    },
    {
        id: 3,
        icon: <img src={LinkedIn} alt="linkedin-icon" />,
        to: "https://www.linkedin.com/company/boushahrigm"
    }
]
export default SocialData