import { FullImgHero } from "../../Components"
import { ListOfServices } from "../../Sections"
import "./style.css"

const index = () => {

    return (
        <>
            <FullImgHero additionalClass="our-services-hero" />
            <ListOfServices />
        </>
    )
}

export default index