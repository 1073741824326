import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes";

const ListOfWarehouses: ListOfDataProps[] = [
    {
        id: 1,
        title: "900",
        description: "Square Meters"
    },
    {
        id: 2,
        title: "ISO",
        description: "complaint"
    },
    {
        id: 3,
        title: "GDP",
        description: "complies with good distribution practices "
    },
    {
        id: 4,
        title: "Chilled Zone",
        description: "fully equipped"
    },
    {
        id: 5,
        title: "Full control & alarm",
        description: "for temperature reading and humidity measurement, ensuring safety at all times  "
    },
    {
        id: 6,
        title: "Our Fleet",
        description: "temperature-controlled vehicles abiding by international standards for safe storage and transportation. "
    }
]
export default ListOfWarehouses