import { MisionVisionData } from "../../../Assets/Data"
import "./MisionVision.css"

const MisionVision = () => {
    const visionMision = MisionVisionData.map(item => (
        <div className="col-12 col-md-6" key={item.id}>
            <h2>{item.title}</h2>
            <p>
                {item.description}
            </p>
        </div>
    ))
    return (
        <div className="container-fluid vision-mision">
            <div className="row">
                {visionMision}
            </div>
        </div>
    )
}

export default MisionVision