import "./Header.css"
import { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { BurgerIcon, LogoCon, Menu, SmallScreenMenu } from "../../Components"
const Header: FC = () => {
    const [isHome, setIsHome] = useState<boolean>(false)
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === "/") {
            setIsHome(true)
        }
        else {
            setIsHome(false)
        }
        setIsVisible(false)
    }, [location.pathname])

    return (
        <>
            <div className={`container-fluid header justi-between-row ${isHome === true && "abs"}`}>
                <LogoCon isHome={isHome} />
                <BurgerIcon setIsVisible={setIsVisible} isVisible={isVisible} />
            </div>
            <Menu isVisible={isVisible} setIsVisible={setIsVisible} />
            <SmallScreenMenu isVisible={isVisible} setIsVisible={setIsVisible} />
        </>
    )
}

export default Header